import { PaletteOptions, alpha, createTheme as createMUITheme } from "@mui/material/styles";
import { CSSProperties, TypographyOptions } from "@mui/material/styles/createTypography";
import { roboto, robotoCondensed, sofiaSansExtraCondensed } from "@/utis/fonts";
import { checkboxClasses } from "@mui/material";
import { PaletteType } from "./common";

declare module "@mui/material/styles" {
  interface Theme {
    borderRadius: (val: number) => string;
  }
  interface ThemeOptions {
    borderRadius?: (val: number) => string;
  }
  interface TypographyVariants {
    number: CSSProperties;
    odds: CSSProperties;
    caption: CSSProperties;
    body1Bold: CSSProperties;
    label: CSSProperties;
    header: CSSProperties;
    sofiaSansHeader: CSSProperties;
    navHeader: CSSProperties;
    body3: CSSProperties;
    supScript: CSSProperties;
    resourceTitle: CSSProperties;
    contentTitle: CSSProperties;
    contentDetail: CSSProperties;
    smallText: CSSProperties;
  }

  interface TypographyVariantsOptions {
    number?: CSSProperties;
    odds: CSSProperties;
    label?: CSSProperties;
    caption?: CSSProperties;
    body1Bold?: CSSProperties;
    header?: CSSProperties;
    sofiaSansHeader?: CSSProperties;
    navHeader?: CSSProperties;
    body3?: CSSProperties;
    supScript: CSSProperties;
    resourceTitle?: CSSProperties;
    contentTitle?: CSSProperties;
    contentDetail?: CSSProperties;
    smallText?: CSSProperties;
  }

  interface Palette {
    border: { odds: string; basic: string; custom?: string; oddsCustom?: string } & Palette["text"];
    customBackground: {
      cards: string;
      progress: string;
      custom?: string;
      footer?: string;
      planCard?: string;
      cardHeader?: string;
    };
    tertiary: Palette["primary"];
    maintenance: Palette["primary"];
    button: {
      active: string;
      inActive: string;
      text: string;
      activeText: string;
      common: string;
    } & Palette["background"];
    profit: string;
    roi: {
      positive: string;
      negative: string;
    };
    highlightText?: string;
    basic: string;
    basicReverse: string;
    warningLight: string;
    yellowAccent: string;
    boxShadow: string;
    profitText?: string;
    contentText?: string;
    modal: {
      background: string;
      border: string;
      activeButton: string;
      buttonBorder: string;
    };
    grayScale: {
      zeropercent: string;
      twopercent: string;
      fourpercent: string;
      sixpercent: string;
      eightpercent: string;
      tenpercent: string;
      twelevepercent: string;
      twentypercent: string;
      twentyfourpercent: string;
      bordereightpercent: string;
      bordersixpercent: string;
    };
  }

  interface PaletteOptions {
    border?: { odds?: string; basic?: string; custom?: string; oddsCustom?: string } & PaletteOptions["text"];
    customBackground: {
      cards?: string;
      progress?: string;
      custom?: string;
      footer?: string;
      planCard?: string;
      cardHeader?: string;
    } & PaletteOptions["background"];
    tertiary?: PaletteOptions["primary"];
    maintenance?: PaletteOptions["primary"];
    button?: {
      active?: string;
      inActive?: string;
      text?: string;
      activeText?: string;
      common?: string;
    } & PaletteOptions["background"];
    profit?: string;
    roi?: {
      positive?: string;
      negative?: string;
    };
    basic?: string;
    basicReverse?: string;
    warningLight?: string;
    yellowAccent?: string;
    boxShadow?: string;
    profitText?: string;
    highlightText?: string;
    contentText?: string;
    modal: {
      background: string;
      border: string;
      activeButton: string;
      buttonBorder: string;
    };
    grayScale: {
      zeropercent: string;
      twopercent: string;
      fourpercent: string;
      sixpercent: string;
      eightpercent: string;
      tenpercent: string;
      twelevepercent: string;
      twentypercent: string;
      twentyfourpercent: string;
      bordereightpercent: string;
      bordersixpercent: string;
    };
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    number: true;
    odds: true;
    caption: true;
    body1Bold: true;
    label: true;
    header: true;
    sofiaSansHeader: true;
    navHeader: true;
    body3: true;
    supScript: true;
    smallText: true;
    resourceTitle: true;
    contentTitle: true;
    contentDetail: true;
  }
}

// Update the Icon's color options to include a salmon option
declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    maintenance: true;
  }
}

const violetBase = "#7F00FF";

const lightPalette: PaletteOptions = {
  mode: PaletteType.Light,
  primary: {
    main: "#ECECEC",
  },
  secondary: {
    main: "#1C212B",
    contrastText: "#ECECEC",
  },
  tertiary: {
    main: "#7E7E7E",
  },
  text: {
    primary: "#1C212B",
    secondary: "#111318",
  },
  highlightText: "#FFC56F",
  customBackground: {
    cards: "#ECECEC",
    progress: alpha("#7E7E7E", 0.08),
    custom: alpha("#7E7E7E", 0.72),
    footer: "#ECECEC",
    cardHeader: "#D9D9D9",
  },
  border: {
    primary: "#1C212B",
    secondary: "#8C8C8C",
    odds: "#ECECEC",
    basic: "#DADAE5",
    oddsCustom: alpha("#ECECEC", 0.32),
    custom: alpha("#FFFFFF", 0.12),
  },
  button: {
    default: "#FCFCFE",
    active: "#5F5F5F",
    inActive: "#ECECEC",
    text: "#317B9A",
    activeText: "#ECECEC",
    common: "#317B9A",
  },
  roi: {
    positive: "#30A864",
    negative: "#f53c3c",
  },
  profit: "#30A864",
  basic: "#000000",
  basicReverse: "#FFFFFF",
  warningLight: "#ffb74d60",
  profitText: "#5B75531F",
  contentText: "#000000",
  modal: {
    background: "#FFFFFFE5",
    border: "#DEE2E6",
    activeButton: "#111318",
    buttonBorder: "#7E7E7E",
  },
  background: {
    default: "#ECECEC",
  },
  divider: "#343434",
  grayScale: {
    zeropercent: "#00000000",
    twopercent: "#00000005",
    fourpercent: "#0000000A",
    sixpercent: "#0000000F",
    eightpercent: "#111318",
    tenpercent: "#00000019",
    twelevepercent: "#0000001F",
    twentypercent: " #ECECEC33",
    twentyfourpercent: "#ECECEC3D",
    bordereightpercent: "#111318",
    bordersixpercent: "#1113186D",
  },
};

const darkPalette: PaletteOptions = {
  mode: PaletteType.Dark,
  primary: {
    main: "#1C212B",
  },
  secondary: {
    main: "#D9D9D9",
    contrastText: "#111318",
  },
  tertiary: {
    main: "#7E7E7E",
  },
  error: {
    main: "#BF3434",
    dark: "#BF34341F",
    light: "#BF34347A",
  },
  maintenance: {
    main: alpha(violetBase, 0.7),
    light: alpha(violetBase, 0.5),
  },
  text: {
    primary: "#ECECEC",
    secondary: "#7E7E7E",
  },
  highlightText: "#FFC56F",
  background: {
    default: "#1C212B",
  },
  customBackground: {
    cards: "#161920",
    progress: alpha("#7E7E7E", 0.08),
    custom: alpha("#7E7E7E", 0.72),
    planCard: alpha("#FFFFFF", 0.06),
    footer: "#1E232C",
    cardHeader: "#111318",
  },
  yellowAccent: "#E2CB00",
  boxShadow: "#00000040",
  border: {
    primary: "#ECECEC",
    secondary: "#7E7E7E",
    odds: "#282828",
    basic: "#24272D",
    oddsCustom: alpha("#ECECEC", 0.32),
    custom: alpha("#FFFFFF", 0.12),
  },
  button: {
    default: "#1D2F41",
    active: "#317B9A",
    inActive: "#09131E",
    text: "#1D2F41",
    activeText: "#ECECEC",
    common: "#317B9A",
  },
  divider: "#343434",
  roi: {
    positive: "#58d32c",
    negative: "#f53c3c",
  },
  modal: {
    background: "#111318CC",
    border: "#ECECEC",
    activeButton: "#ECECEC",
    buttonBorder: "#7E7E7E",
  },
  profit: "#30A864",
  basic: "#FFFFFF",
  basicReverse: "#000000",
  warningLight: "#ffb74d60",
  profitText: "#30A8640F",
  contentText: "#ECECEC",
  grayScale: {
    zeropercent: "#FFFFFF00",
    twopercent: "#FFFFFF05",
    fourpercent: "#FFFFFF0A",
    sixpercent: "#FFFFFF0F",
    eightpercent: "#FFFFFF14",
    tenpercent: "#FFFFFF19",
    twelevepercent: "#FFFFFF1F",
    twentypercent: " #ECECEC33",
    twentyfourpercent: "#ECECEC3D",
    bordereightpercent: "#FFFFFF14",
    bordersixpercent: "#FFFFFF0F",
  },
};

// const typographyMobile: TypographyOptions = {
//   ...roboto.style,
//   h1: {
//     fontSize: 20,
//     fontWeight: 400,
//   },
//   h2: {
//     fontSize: 16,
//   },
//   body1: {
//     fontSize: 12,
//     fontWeight: 400,
//   },
//   body1Bold: {
//     fontSize: 12,
//     fontWeight: 700,
//     lineHeight: "14px",
//   },
//   body2: {
//     fontSize: 10,
//     fontWeight: 400,
//   },
//   body3: {
//     fontSize: 12,
//     fontWeight: 400,
//   },
//   button: {
//     fontSize: 16,
//     fontWeight: 400,
//   },
//   number: {
//     fontFamily: "'Lato', sans-serif",
//     fontSize: 12,
//     fontWeight: 700,
//   },
//   odds: {
//     ...robotoMono.style,
//     fontSize: 12,
//     fontWeight: 700,
//   },
//   label: {
//     fontSize: 10,
//   },
//   header: {
//     ...sofiaSansExtraCondensed.style,
//     letterSpacing: 1,
//     fontWeight: 700,
//     fontSize: 24,
//     textTransform: "uppercase",
//   },
//   navHeader: {
//     fontSize: 16,
//   },
// };

// const typographyDesktop: TypographyOptions = {
//   ...roboto.style,
//   h1: {
//     fontSize: 36,
//     fontWeight: 400,
//   },
//   h2: {
//     fontSize: 24,
//   },
//   body1: {
//     fontSize: 18,
//     fontWeight: 400,
//   },
//   body1Bold: {
//     fontSize: 18,
//     fontWeight: 700,
//   },
//   body2: {
//     fontSize: 14,
//     fontWeight: 400,
//   },
//   body3: {
//     fontSize: 16,
//     fontWeight: 400,
//   },
//   button: {
//     fontSize: 24,
//     fontWeight: 400,
//   },
//   number: {
//     fontFamily: "'Lato', sans-serif",
//     fontSize: 16,
//     fontWeight: 700,
//   },
//   odds: {
//     ...robotoMono.style,
//     fontSize: 16,
//     fontWeight: 700,
//   },
//   label: {
//     fontSize: 12,
//   },
//   header: {
//     ...sofiaSansExtraCondensed.style,
//     letterSpacing: 1,
//     fontWeight: 700,
//     fontSize: 24,
//     textTransform: "uppercase",
//   },
//   navHeader: {
//     fontSize: 20,
//   },
// };

const typography: TypographyOptions = {
  ...roboto.style,
  h1: {
    fontSize: 36,
    fontWeight: 400,
  },
  h2: {
    fontSize: 24,
  },
  body1: {
    fontSize: 18,
    fontWeight: 400,
  },
  body1Bold: {
    fontSize: 18,
    fontWeight: 700,
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
  },

  body3: {
    fontSize: 16,
    fontWeight: 400,
  },
  smallText: {
    fontSize: 10,
    fontWeight: 400,
  },
  button: {
    fontSize: 24,
    fontWeight: 400,
  },
  number: {
    fontFamily: "'Lato', sans-serif",
    fontSize: 16,
    fontWeight: 700,
  },
  odds: {
    fontSize: 16,
    fontWeight: 700,
  },
  label: {
    fontSize: 12,
  },
  header: {
    ...robotoCondensed.style,
    fontWeight: 700,
    fontSize: 18,
    textTransform: "capitalize",
    lineHeight: "normal",
  },
  sofiaSansHeader: {
    ...sofiaSansExtraCondensed.style,
    fontWeight: 900,
    textTransform: "uppercase",
    lineHeight: "normal",
  },
  navHeader: {
    fontSize: 20,
  },
  supScript: {
    fontSize: 8,
  },
  resourceTitle: {
    ...sofiaSansExtraCondensed.style,
    fontSize: 32,
    textTransform: "uppercase",
    lineHeight: "normal",
  },
  contentTitle: {
    ...sofiaSansExtraCondensed.style,
    fontWeight: 900,
    fontSize: 40,
    textTransform: "uppercase",
    lineHeight: "normal",
  },
  contentDetail: {
    fontSize: 18,
  },
};

const createTheme = (paletteType?: PaletteType, isMobile?: boolean) => {
  const palette = paletteType === PaletteType.Light ? lightPalette : darkPalette;
  // const typography = isMobile ? typographyMobile : typographyDesktop;
  // const spacing = isMobile ? 5 : 8;
  const spacing = 8;
  const borderRadius = 5;
  return createMUITheme({
    palette,
    typography: {
      allVariants: {
        color: paletteType === PaletteType.Light ? lightPalette.text?.primary : darkPalette.text?.primary,
      },
      ...typography,
    },
    shape: {
      borderRadius,
    },
    borderRadius: (val: number) => `${val * borderRadius}px`,
    components: {
      MuiRadio: {
        styleOverrides: {
          root: {
            color: palette.text?.primary,
            "& .Mui-checked": {
              color: `${palette.text?.primary} !important`,
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          autoComplete: "off",
          autoCorrect: "off",
        },
        styleOverrides: {
          root: {
            "& label": {
              color: palette.text?.secondary,
            },
            "& label.Mui-focused": {
              color: palette.text?.secondary,
            },
            ".MuiInputLabel-shrink": {
              marginTop: -4,
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: "8px !important",
            border: "none",

            "&.Mui-selected": {
              // @ts-expect-error secondary type is not detecting in custom styling
              color: palette.secondary?.contrastText,
              backgroundColor: palette.text?.primary,
              "&:hover": {
                backgroundColor: palette.text?.primary,
              },
            },
            "&.Mui-disabled": {
              border: "none",
              opacity: 0.5,
            },
          },
        },
      },

      MuiSelect: {
        styleOverrides: {
          select: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: spacing,
            paddingRight: spacing,
            border: `1px solid ${palette.border?.primary}`,
            fontSize: typography.label?.fontSize,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              // @ts-expect-error secondary type is not detecting in custom styling
              color: palette.secondary?.contrastText,
              backgroundColor: palette.text?.primary,

              "&:hover": {
                color: palette.text?.primary,
              },
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            textTransform: "none",
            borderRadius: "8px",
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            background: palette.grayScale.twopercent,
            border: `1px solid ${palette.grayScale.fourpercent}`,
            "&.Mui-disabled": {
              borderColor: palette.border?.secondary,
              color: palette.text?.secondary,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius,
            ".MuiTouchRipple-ripple .MuiTouchRipple-child": {
              borderRadius,
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            input: {
              paddingTop: 8,
              paddingBottom: 6,
              height: 30,
              ...typography.body3,
            },
            ".MuiInputAdornment-positionStart": {
              marginBottom: 14,
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: palette.text?.primary,
            [`&.${checkboxClasses.checked}`]: {
              color: palette.text?.primary,
            },
          },
        },
      },
    },
    spacing,
  });
};

export { createTheme };
