/* eslint-disable no-param-reassign */
import { League } from "@/components/Common/Enums/LeagueEnum";
import { TeamType } from "@/components/Common/Fragments/ToolsSubscriptionFragment";
import { IPlayerTypeUpdatedon } from "@/components/Odds/common";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { differenceInHours } from "date-fns";

// TODO: Make this global cache some sort of

export type GameWeatherType = {
  time: number | null;
  tempF: number;
  condition: string;
  windMPH: number;
  windDirection: string;
  feelsLikeTempF: number;
  chanceOfRain: number;
  precipInches: number;
};

export type PlayerType = {
  id: string;
  firstName: string;
  lastName: string;
};

export enum TennisCompetitionTypeEnum {
  SINGLES = "SINGLES",
  DOUBLES = "DOUBLES",
  MIXED = "MIXED",
  MIXED_DOUBLES = "MIXED_DOUBLES",
}

export type TennisCompetitionType = {
  id: number;
  name: string;
  type: TennisCompetitionTypeEnum;
  isMen: boolean;
};

export type GameData = {
  id: string;
  homeTeam: TeamType;
  awayTeam: TeamType;
  startDateTime: number;
  weather?: GameWeatherType[];
  competitonId?: number;
  competition?: TennisCompetitionType;
};
export type GameDataUpdatedon = {
  lastUpdatedOn?: number;
} & GameData;

export type GameDataCacheType = {
  gameData: Partial<Record<string, GameDataUpdatedon>>;
  playerData: Partial<Record<League, Record<number, IPlayerTypeUpdatedon>>>;
};

const initialState: GameDataCacheType = {
  gameData: {},
  playerData: {},
};

export const gameDataCache = createSlice({
  name: "gameDataCache",
  initialState,
  reducers: {
    setGamesData: (state, action: PayloadAction<GameDataUpdatedon[]>) => {
      const nowDate = Date.now();
      const data = action.payload;
      data.forEach((game) => {
        state.gameData[game.id] = game;
      });

      // Delete the game Data if the game has not been updated from over an hour
      Object.keys(state.gameData).forEach((gameId) => {
        if (state.gameData[gameId]?.lastUpdatedOn && differenceInHours(nowDate, state.gameData[gameId]!.lastUpdatedOn!) > 1) {
          delete state.gameData[gameId];
        }
      });
    },
    setPlayerData: (state, action: PayloadAction<{ league: League; players: IPlayerTypeUpdatedon[] }>) => {
      const nowDate = Date.now();
      const { league, players } = action.payload;
      if (!(league in state.playerData)) {
        state.playerData[league] = {};
      }

      players.forEach((player) => {
        state.playerData[league]![player.id] = {
          ...player,
          lastUpdatedOn: nowDate
        };
      });

      if (state.playerData[league]) {
        Object.keys(state.playerData[league]!).forEach((playerId) => {
          const player = state.playerData[league]![Number(playerId)];
          if (player?.lastUpdatedOn && differenceInHours(nowDate, player.lastUpdatedOn) > 1) {
            delete state.playerData[league]![Number(playerId)];
          }
        });
      }
    },
    clearCacheData: (state) => {
      state.gameData = {};
      state.playerData = {};
    },
  },
});

export const gameDataCacheAction = gameDataCache.actions;

export default gameDataCache.reducer;
