import { fetchGraphql } from "@/fetchGraphql";
import separateErrorCodeAndMssgFromError from "@/utis/getErrorCodeFromMessage";
import * as Sentry from "@sentry/nextjs";
import Cookies from "js-cookie";
import { gcSetApiToken } from "@/api/api";
import { errorMessage } from "./errorMessage";
import { USER_RESTRICTIONS_FRAGMENT, UserRestrictionsFragment } from "./UserRestrictions/common";

export const USER_COOKIE_NAME = `pto_user_${process.env.NODE_ENV}`;

export const REFETCH_TOKEN_INTERVAL = 30;

export type UserTokens = {
  anonymousToken: {
    token: string;
  };
};

export type UserRefreshTokens = {
  refreshToken?: {
    token: string;
  };
  userId?: string;
};

const GET_TOKEN = `
  mutation GetToken($userId: Guid, $refreshToken: String) {
    user {
      token(userId: $userId, refreshToken: $refreshToken) {
        accessToken
      }
    }
  }
`;

type GetToken = {
  user: {
    token: {
      accessToken: string;
    };
  };
};

type GetTokenVariables = {
  userId?: string;
  refreshToken?: string;
};

export function getCookieUserRefreshPreferences(cookie: string | undefined): UserRefreshTokens | null {
  if (!cookie) return null;
  try {
    const prefs: UserRefreshTokens = JSON.parse(cookie!);
    return prefs;
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setExtra("cookie", cookie !== undefined ? "Cookie is not empty" : "Cookie is empty");
      scope.setExtra("cookie", cookie);
      scope.setExtra("Error", error);
      Sentry.captureException(
        new Error(`Custom Error - Error Occured while fetching token in ${cookie ? "Layout" : "Refreshing token"}`),
      );
    });
    return null;
  }
}

export function getUserRefreshToken(): UserRefreshTokens | null {
  return getCookieUserRefreshPreferences(Cookies.get(USER_COOKIE_NAME));
}

export function updateUserRefreshTokens(newUserTokens: UserRefreshTokens) {
  Cookies.set(USER_COOKIE_NAME, JSON.stringify(newUserTokens), { expires: 180 });
}

export function deleteCookies() {
  Cookies.remove(USER_COOKIE_NAME);
}

export function logOut(isReload = true) {
  // const userTokens = getUserRefreshToken();
  // if (!userTokens?.refreshToken?.token) {
  //   throw new Error('logOut: could not log out: no token');
  // }
  deleteCookies();
  if (isReload) {
    window.location.reload();
  }
}

export async function refreshAnonymousToken(userTokensParam?: UserRefreshTokens | null) {
  const userTokens = userTokensParam || getUserRefreshToken();
  const variables =
    userTokens?.refreshToken?.token && userTokens?.userId
      ? {
        refreshToken: userTokens.refreshToken?.token,
        userId: userTokens?.userId,
      }
      : undefined;
  const { data, errors } = await fetchGraphql<GetToken, GetTokenVariables>({
    query: GET_TOKEN,
    variables,
  });

  if (errors?.length) {
    const [customErrorCode, errMsg] = separateErrorCodeAndMssgFromError(errors[0].message);
    // Sentry.withScope((scope) => {
    //   scope.setExtra("Custom Error Code", customErrorCode);
    //   scope.setExtra("Error Message", errMsg);
    //   scope.setExtra("userTokensParam", userTokensParam);
    //   scope.setExtra("errors", errors[0]);
    //   scope.setUser({ userId: userTokens?.userId });
    //   Sentry.captureException(new Error("Error has occured while fetching the user"), {
    //     tags: {
    //       priority: customErrorCode ? "low" : "high",
    //     },
    //   });
    // });

    if (customErrorCode === "0002") {
      logOut(false); // 5 sec
      throw new Error(errorMessage["1003"], { cause: customErrorCode });
    }
    if (customErrorCode === "0001" || customErrorCode === "0003") {
      logOut(false); // 5 sec
      throw new Error(errorMessage[customErrorCode], { cause: customErrorCode });
    }
    if (!!customErrorCode && customErrorCode in errorMessage) {
      throw new Error(errorMessage[customErrorCode as keyof typeof errorMessage]);
    }
    throw new Error(errMsg);
  }
  gcSetApiToken(data.user.token.accessToken);

  return data.user.token.accessToken;
}

// Ensure this is in order from lower to higher
export enum PackageTypeEnum {
  ANONYMOUS = "ANONYMOUS",
  LOGGED_IN = "LOGGED_IN",
  STARTER = "STARTER",
  INTERMEDIATE = "INTERMEDIATE",
  ADVANCED = "ADVANCED",
}

export const USER_FRAGMENT = `
  fragment UserFragment on PickUserType {
    userId
    emailAddress
    settings
    activePackageType
  }
`;

const GET_USER = `
  query GetUser {
    user {
      user {
      ...UserFragment
      }
      ...UserRestrictionsFragment
    }
  }
  ${USER_FRAGMENT}
  ${USER_RESTRICTIONS_FRAGMENT}
`;

export type UserType = {
  userId: string;
  emailAddress: string;
  settings: string;
  activePackageType: PackageTypeEnum;
};

export type UserQueryType = {
  user: UserType | null;
} & UserRestrictionsFragment;

type GetUser = {
  user: UserQueryType | null;
};

type FetchUserParams = { apiToken: string };

export async function fetchUser(params = {} as FetchUserParams): Promise<UserQueryType | null | undefined> {
  const { apiToken } = params;

  const resp = await fetchGraphql<GetUser>({ query: GET_USER, apiToken });
  if (resp.errors?.length) {
    Sentry.withScope((scope) => {
      scope.setExtra("apiToken", apiToken);
      scope.setExtra("Error", resp.errors?.[0]);
      Sentry.captureException(new Error("Custom Error Could not fetch user: errors are present"));
    });
    throw new Error(`could not fetch user: errors are present - ${resp.errors[0].message}`, {
      cause: resp.errors[0].cause,
    });
  }
  const { user } = resp.data;
  return user;
}
