import { CommonFilterType, KeyOfPages } from "@/components/Common/Filters/commonFilterType";
import { gql } from "@apollo/client";
import { InputFilterBaseRequestType } from "./InputFilterBaseRequestType";
import { PackageTypeEnum } from "../common";

export enum ToolEnumType {
  ARBITRAGE = "ARBITRAGE",
  MIDDLE = "MIDDLE",
  FREE_BET_CONVERTER = "FREE_BET_CONVERTER",
  LOW_HOLD = "LOW_HOLD",
  EV = "EV",
  ODD_SCREEN = "ODD_SCREEN",
  SPORTSBOOK = "SPORTSBOOK",
}

export type PickUserFilterType = {
  id: string;
  userId?: string;
  toolEnum: ToolEnumType;
  name: string;
  icon?: string;
  color?: string;
  order?: number;
  isDefault?: boolean;
  filters: InputFilterBaseRequestType[];
  json: string | undefined;
  createdDateTime?: number;
  lastModifiedDateTime?: number;
  lastModifiedPackageTypeEnum?: PackageTypeEnum;
};

export type UserFiltersType = PickUserFilterType & {
  commonFilter: CommonFilterType;
};

export type InputCreatePickUserFilterType = {
  name: string;
  toolEnum: ToolEnumType;
  icon?: string;
  color?: string;
  order?: number;
  // while creating a filter always send filters object as default so that there will be no null filters in backend
  filters: InputFilterBaseRequestType[];
  json: string | undefined;
  isDefault?: boolean;
};

export type InputEditPickUserFilterType = {
  id: string;
  name: string;
  toolEnum: ToolEnumType;
  icon?: string;
  color?: string;
  json: string | undefined;
  filters: InputFilterBaseRequestType[];
  isDefault?: boolean;
  order?: number;
};

export function mapToolEnumKeyToPathKey(toolEnum: ToolEnumType): KeyOfPages {
  switch (toolEnum) {
    case ToolEnumType.ARBITRAGE:
      return "arbitrages";
    case ToolEnumType.MIDDLE:
      return "middles";
    case ToolEnumType.FREE_BET_CONVERTER:
      return "freebet";
    case ToolEnumType.LOW_HOLD:
      return "lowhold";
    case ToolEnumType.EV:
      return "expectedvalue";
    case ToolEnumType.ODD_SCREEN:
      return "odds-screen";
    case ToolEnumType.SPORTSBOOK:
      return "game";
    default:
      return "arbitrages";
  }
}
export function mapPathKeyToToolEnum(pathKey: KeyOfPages): ToolEnumType {
  switch (pathKey) {
    case "arbitrages":
      return ToolEnumType.ARBITRAGE;
    case "middles":
      return ToolEnumType.MIDDLE;
    case "freebet":
      return ToolEnumType.FREE_BET_CONVERTER;
    case "lowhold":
      return ToolEnumType.LOW_HOLD;
    case "expectedvalue":
      return ToolEnumType.EV;
    case "odds-screen":
      return ToolEnumType.ODD_SCREEN;
    case "game":
      return ToolEnumType.SPORTSBOOK;
    default:
      return ToolEnumType.ARBITRAGE;
  }
}

export const PICKUSERFILTER_FRAGMENT = `
	fragment PickUserFilterTypeFragment on PickUserFilterType {
    id
    userId
    toolEnum
    name
    icon
    color
    order
    isDefault
    filters {
      betSite
      league
      sport
      betMarketType
      betMarketTypeGroup
      requestHash
      requestData {
        betSites
        leagues
        afterDateTime
        beforeDateTime
        minAmericanOdds
        maxAmericanOdds
        upcomingGameStatus
        liveGameStatus
        futureHours
        beforeHours
        foundSecondsAgo
        minROI
        maxROI
        betMarketTypes
        betMarketTypeGroups
        region
        country
        take
        rOIRestrictedBetSites
        rOIRestrictedLeagues
        minMaxWager
        ignoreDuplicateBetSites
        maximumLegs
        distance
        expectedValueWeights {
          betSite
          weight
          widthWeight
        }
        expectedValueFormulaWeight {
          formula
          weight
        }
        expectedValueFormulaCase
        minWidth
        blockedBetHashCodes
        isInclude
        includeExcludes {
          gameId
          playerId
          teamId
          leagueEnum
        }
      }
      isRestricted
    }
    json
    createdDateTime
    lastModifiedDateTime
    lastModifiedPackageTypeEnum
	}
`;

export const GET_USER_FILTERS = gql`
  query GetUserFilters($toolEnum: ToolEnumType!) {
    user {
      filter {
        filters(toolEnum: $toolEnum) {
          ...PickUserFilterTypeFragment
        }
      }
    }
  }
  ${PICKUSERFILTER_FRAGMENT}
`;

export type GetUserFilters = {
  user: {
    filter: {
      filters: PickUserFilterType[];
    };
  };
};

export type GetUserFiltersVariables = {
  toolEnum: ToolEnumType;
};

export const ADD_USER_FILTER = gql`
  mutation AddUserFilter($filter: InputCreatePickUserFilterType!) {
    user {
      filters {
        add(filter: $filter) {
          id
        }
      }
    }
  }
`;

export type AddUserFilter = {
  user: {
    filters: {
      add: Pick<PickUserFilterType, "id">;
    };
  };
};

export type AddUserFilterVariables = {
  filter: InputCreatePickUserFilterType;
};

export const EDIT_USER_FILTERS = gql`
  mutation EditUserFilters($filters: [InputEditPickUserFilterType]!) {
    user {
      filters {
        edits(filters: $filters) {
          id
        }
      }
    }
  }
`;

export type EditUserFilters = {
  user: {
    filters: {
      edits: Pick<PickUserFilterType, "id">[];
    };
  };
};

export type EditUserFiltersVariables = {
  filters: InputEditPickUserFilterType[];
};

export const REMOVE_USER_FILTER = gql`
  mutation RemoveUserFilter($id: Guid!) {
    user {
      filters {
        remove(id: $id)
      }
    }
  }
`;

export type RemoveUserFilter = {
  user: {
    filters: {
      remove: boolean;
    };
  };
};

export type RemoveUserFilterVariables = {
  id: string;
};
