import { ExpectedValueFormulaCaseEnum, ExpectedValueFormulaEnum } from "../Common/Enums/ExpectedValueFormulaEnum";

export type EVSportBookType = {
  betSite: string;
  weight: number;
  widthWeight?: number;

  // Find out why apollo is not removing this
  __typename?: string;
};

export type ExpectedValueFormulaWeightType = {
  formula: ExpectedValueFormulaEnum;
  weight: number | undefined;

  // Find out why apollo is not removing this
  __typename?: string;
};

export const defaultExpectedValueWeights: EVSportBookType[] = [
  { betSite: "PINNACLE", weight: 1 },
  { betSite: "CIRCA", weight: 1 },
];
export const EV_FORMULA_WEIGHT_DEFAULT = Object.values(ExpectedValueFormulaEnum).map((efe) => ({ formula: efe, weight: 1 }));

export const defaultExpectedValueFormulaCase = ExpectedValueFormulaCaseEnum.WORST_CASE;

export function convertEvFormulaWeightQueryToApiVariable(evFormulaWeight: ExpectedValueFormulaWeightType[] | number[] | undefined) {
  if (!evFormulaWeight) return undefined;
  if (Array.isArray(evFormulaWeight) && evFormulaWeight.some(item => typeof item === "number")) {
    return Object.values(ExpectedValueFormulaEnum).reduce<ExpectedValueFormulaWeightType[]>((acc, curr, i) => {
      acc.push({
        formula: curr,
        // @ts-expect-error Type error because number || ExpectedValueFormulaWeightType
        weight: evFormulaWeight[i] ?? 0,
      });
      return acc;
    }, []);
  }
  // @ts-expect-error Type error because number || ExpectedValueFormulaWeightType
  return evFormulaWeight?.map((evFw: ExpectedValueFormulaWeightType) => ({ formula: evFw.formula, weight: evFw.weight ?? 0 }));
}

export function convertEvWeightQueryToApiVariable(evWeight: EVSportBookType[] | string | undefined) {
  if (!evWeight) return undefined;
  if (typeof evWeight === "string") {
    return (JSON.parse(evWeight) as EVSportBookType[])?.map(({ __typename, ...rest }) => rest);
  }
  return evWeight?.map(({ __typename, ...rest }) => rest);
}
