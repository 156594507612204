/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TennisCompetitionType } from "@/components/Odds/common";


export type CompetitionCacheType = {
    competitions: Record<number, TennisCompetitionType>;
    lastUpdated: Record<number, number>;
};


const initialState: CompetitionCacheType = {
    competitions: {},
    lastUpdated: {},
};

export const competitionCache = createSlice({
    name: "competitionCache",
    initialState,
    reducers: {
        setCompetition: (state, { payload }: PayloadAction<TennisCompetitionType>) => {
            const { id } = payload;
            // Only update if competition doesn't exist or has different data
            if (!state.competitions[id] ||
                JSON.stringify(state.competitions[id]) !== JSON.stringify(payload)) {
                return {
                    ...state,
                    competitions: {
                        ...state.competitions,
                        [id]: payload
                    },
                    lastUpdated: {
                        ...state.lastUpdated,
                        [id]: Date.now()
                    }
                };
            }
            return state;
        },
        clearAllCache: (state) => {
            state.competitions = {};
            state.lastUpdated = {};
        },
    },
});

export const competitionCacheAction = competitionCache.actions;
export default competitionCache.reducer;