export enum ExpectedValueFormulaEnum {
  ADDITIVE = "ADDITIVE",
  MULTIPLICATIVE = "MULTIPLICATIVE",
  POWER = "POWER",
  // SHIN = "SHIN",
}

export enum ExpectedValueFormulaEnumOddScreen {
  ADDITIVE = "ADDITIVE",
  MULTIPLICATIVE = "MULTIPLICATIVE",
  POWER = "POWER",
  PROBIT = "PROBIT",
  // SHIN = "SHIN",
}

export enum ExpectedValueFormulaCaseEnum {
  WORST_CASE = "WORST_CASE",
  BEST_CASE = "BEST_CASE",
  WEIGHTED = "WEIGHTED",
}
