
import { IGameLiveDataType } from "@/components/Common/GameLiveData/common";
import { PartialRecord } from "@/utis/partialRecord";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type GameLiveDataType = {
  lastUpdateTime: number;
} & IGameLiveDataType;

const initialState: PartialRecord<string, GameLiveDataType> = {};

const gameLiveData = createSlice({
  name: "gameLiveData",
  initialState,
  reducers: {
    setGameLiveData: (state, action: PayloadAction<IGameLiveDataType>) => {
      const game = action.payload;
      return {
        ...state,
        [game.gameId]: { ...game, lastUpdateTime: new Date().getTime() },
      };
    },
  },
});

export const gameLiveDataAction = gameLiveData.actions;
export default gameLiveData.reducer;