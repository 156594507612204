import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mapPathKeyToToolEnum, PickUserFilterType } from '@/user/user-filters/common';
import { loadState, saveState } from '@/utis/localStorage';
import { getDefaultFilterValuesByPathKey, KeyOfPages } from '@/components/Common/Filters/commonFilterType';
import { GetApiVariablesFromFiltersType } from '@/hooks/useGetApiVariablesFromFilters.common';

const KEY = "FILTERS_DATA";

interface FiltersState {
    filtersByPath: Record<string, PickUserFilterType[]>;
    loading: Record<string, boolean>;
}

const initialState: FiltersState = loadState(KEY) || {
    filtersByPath: {},
    loading: {},
};

const validFilterPaths = ["game", "arbitrages", "middles", "freebet", "lowhold", "expectedvalue", "odds-screen"];
const isValidFilterPath = (pathKey: string) => validFilterPaths.includes(pathKey);

const validateFilters = (filters: PickUserFilterType[]) => filters.filter(filter =>
    filter &&
    typeof filter === 'object' &&
    'id' in filter &&
    'toolEnum' in filter
);

const filtersSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        setFilters(
            state,
            action: PayloadAction<{ pathKey: KeyOfPages; filters: PickUserFilterType[] }>
        ) {
            const { pathKey, filters } = action.payload;
            if (!isValidFilterPath(pathKey)) return state;

            const existingFilters = state.filtersByPath[pathKey] || [];
            const validatedFilters = validateFilters(filters);
            const filtersToUse = validatedFilters.length > 0 ? validatedFilters : existingFilters;

            const newState = {
                ...state,
                filtersByPath: {
                    ...state.filtersByPath,
                    [pathKey]: filtersToUse.map(filter => ({
                        ...filter,
                        toolEnum: mapPathKeyToToolEnum(pathKey)
                    })).sort((a, b) => (a.order || 0) - (b.order || 0)),
                },
                loading: {
                    ...state.loading,
                    [pathKey]: false,
                }
            };

            saveState(KEY, {
                filtersByPath: newState.filtersByPath,
                loading: newState.loading
            });

            return newState;
        },

        setLoading(state, action: PayloadAction<{ pathKey: string; loading: boolean }>) {
            const { pathKey, loading } = action.payload;
            if (!isValidFilterPath(pathKey)) return state;

            return {
                ...state,
                loading: {
                    ...state.loading,
                    [pathKey]: loading,
                }
            };
        },

        clearAllFilters() {
            const cleanState = {
                filtersByPath: {},
                loading: {}
            };
            saveState(KEY, cleanState);
            return cleanState;
        },

        ensureDefaultFilter(state, action: PayloadAction<{ pathKey: KeyOfPages; getApiVariablesFromFilters: GetApiVariablesFromFiltersType }>) {
            const { pathKey, getApiVariablesFromFilters } = action.payload;
            if (!isValidFilterPath(pathKey)) return state;
            const defaultFilterValues = getDefaultFilterValuesByPathKey(pathKey) || {};
            const filters = state.filtersByPath[pathKey] || [];
            if (filters.length === 0 || !filters.some(f => f.isDefault)) {
                const filtersApiVariables = getApiVariablesFromFilters(pathKey, pathKey, defaultFilterValues).requestVariableForPage;
                const defaultFilter: PickUserFilterType = {
                    toolEnum: mapPathKeyToToolEnum(pathKey),
                    name: "Default",
                    icon: "InsertDriveFileOutlined",
                    color: "#7C7C7C",
                    isDefault: true,
                    json: JSON.stringify(defaultFilterValues),
                    filters: filtersApiVariables,
                    id: Date.now().toString(),
                    order: 0
                };

                const newState: FiltersState = {
                    ...state,
                    filtersByPath: {
                        ...state.filtersByPath,
                        [pathKey]: [defaultFilter]
                    }
                };

                return newState;
            }
            return state;
        }
    }
});

export const filtersAction = filtersSlice.actions;
export default filtersSlice.reducer;